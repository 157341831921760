class _ApiProxy {
  _commonHeader = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };

  _buildEndpoint = ({ path }) => path;

  _buildHeaders = ({ token, extraHeaders, skipCommonHeader = false }) => {
    let authHeader = token ? { Authorization: `${token}` } : {};
    if (skipCommonHeader) {
      return authHeader;
    }
    return {
      ...this._commonHeader,
      ...authHeader
    };
  };

  _buildBody = (data, files, encoding = "json") => {
    console.log(files);
    if (encoding === "json") {
      return JSON.stringify(data);
    } else if (encoding === "form-data") {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      for (let key in files) {
        formData.append(key, files[key]);
      }
      console.log("formData", formData);
      return formData;
    }
  };

  get({ path, extraHeaders = {}, token }) {
    return fetch(this._buildEndpoint({ path }), {
      method: "GET",
      headers: this._buildHeaders({ token, extraHeaders })
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  post({ path, extraHeaders = {}, token, data, files, encoding }) {
    let skipCommonHeader = encoding === "form-data";
    return fetch(this._buildEndpoint({ path }), {
      method: "POST",
      headers: this._buildHeaders({ token, extraHeaders, skipCommonHeader }),
      body: this._buildBody(data, files, encoding)
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  put({ path, extraHeaders = {}, token, data, files, encoding }) {
    let skipCommonHeader = encoding === "form-data";
    return fetch(this._buildEndpoint({ path }), {
      method: "PUT",
      headers: this._buildHeaders({ token, extraHeaders, skipCommonHeader }),
      body: this._buildBody(data, files, encoding)
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  delete({ path, extraHeaders = {}, token, data, files, encoding  }) {
    let skipCommonHeader = encoding === "form-data";
    let bodyParam = (data || files) ? {
      body: this._buildBody(data, files, encoding)
    } : {};
    return fetch(this._buildEndpoint({ path }), {
      method: "DELETE",
      headers: this._buildHeaders({ token, extraHeaders }),
      ...bodyParam
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }
}

export default new _ApiProxy();
