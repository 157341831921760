const Settings = {
    siteName: "Aifian API",
    themeColor: "#ff7054",
    endpointsDir: "endpoints-aifian",
  
    webUrl: "https://rev-ec.revtel.tech",
    apiUrl: "https://rev-ec-api.revtel.tech",
    mediaHost: "https://d3hgkmxi9caj7a.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/rev-ec.revtel.tech",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127064537-1",
    slsApi: {
      host: "https://1611qza2rg.execute-api.ap-northeast-1.amazonaws.com/stg/",
    }
};

module.exports = Settings;

